import {
  forwardRef,
  ForwardRefRenderFunction,
  Ref,
  useState,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react'
import map from 'lodash/map'
import get from 'lodash/get'

import { ReactComponent as IconMicrophone } from 'shared/assets/faMicrophone.svg'
import { ReactComponent as IconCamera } from 'shared/assets/faCamera.svg'
import { ReactComponent as IconClosedCaptioning } from 'shared/assets/faClosedCaptioning.svg'
import { ReactComponent as IconXMark } from 'shared/assets/faXMark.svg'
import { ReactComponent as FFLogo } from 'shared/assets/facesign_logo_gray.svg'
import CircleButton from 'shared/components/CircleButton'
import Tooltip from 'shared/components/Tooltip'
import Accordion from 'shared/components/Accordion'

export interface IControlPanel {
  hide: () => void
  click: () => void
  setSpeech: (v: string) => void
}

export type Step = {
  id: string
  text: string
  isAvatar: boolean
}

type Props = {
  permissionsError: string | null
  controls: { camera: boolean; mic: boolean }
  setControls: ({ camera, mic }: { camera: boolean; mic: boolean }) => void
  handleClose: () => void
  permissionsChecked: boolean
  steps: Step[]
  userName: string | null
  isRecording: boolean
  orientation: 'portrait' | 'landscape' | ''
  devices: MediaDeviceInfo[]
  setSelectedDeviceId: (v: string | null) => void
  selectedDeviceId: string | null
  availableLanguages: { createdAt: number; id: string; title: string }[]
  lang: string
  onChangeLang: (lang: string) => void
  subtitlesOpenedByDefault?: boolean
}

const ControlPanel: ForwardRefRenderFunction<IControlPanel, Props> = (
  {
    permissionsError,
    controls,
    setControls,
    handleClose,
    permissionsChecked,
    steps,
    userName,
    isRecording,
    orientation,
    devices,
    setSelectedDeviceId,
    selectedDeviceId,
    availableLanguages,
    lang,
    onChangeLang,
    subtitlesOpenedByDefault = false
  },
  ref: Ref<unknown>
) => {
  const [subtitlesShow, setSubtitlesShow] = useState<boolean>(
    subtitlesOpenedByDefault
  )
  const [languagesShow, setLanguagesShow] = useState<boolean>(false)
  const [hidding, setHidding] = useState<boolean>(false)
  const [speech, setSpeech] = useState<string>('')
  const [cameraSelectShow, setCameraSelectShow] = useState<boolean>(false)
  const messagesEndRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    click: () => {
      setHidding(!hidding)
    },
    setSpeech: (v: string) => setSpeech(v)
  }))

  useEffect(() => {
    if (isRecording) {
      setSpeech('')
    }
  }, [isRecording])

  const handleMicOrCameraClick = (type: 'mic' | 'camera') => {
    setControls({ ...controls, [type]: !controls[type] })
  }

  useEffect(() => {
    if (permissionsError) {
      setControls({ camera: false, mic: false })
    }
  }, [permissionsError])

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [steps])

  useEffect(() => {
    if (orientation === 'portrait' && !subtitlesOpenedByDefault) {
      setTimeout(() => {
        setHidding(true)
      }, 2000)
    }
  }, [])

  const renderStep = (s: Step) => {
    const speaker = s.isAvatar ? 'Fay' : userName ?? 'Anonymous'
    return (
      <p key={s.id} className='text-gray-100 text-sm font-light italic'>
        {speaker}: {get(s, 'text', '')}
      </p>
    )
  }

  const onLangClick = (l: string) => {
    if (l !== lang) {
      onChangeLang(l)
    }
  }

  return (
    <div
      className={`transition-opacity duration-200 landscape:w-min landscape:mx-auto portrait:w-full absolute bottom-0 ${
        hidding ? 'opacity-0' : 'opacity-100'
      } left-0 right-0`}
    >
      <div className={hidding ? 'hidden' : ''}>
        <div className='relative landscape:w-full landscape:rounded-t portrait:w-full flex flex-shrink-0 flex-row gap-[20px] justify-between py-4 bg-blackAlpha-800 px-6'>
          <div className='flex flex-shrink-0 flex-grow-1 flex-row gap-[20px] absolute landscape:w-[116px] portrait:w-[calc(20%+3rem)]'>
            {!permissionsChecked && !hidding && (
              <Tooltip
                value='Enable Camera + Microphone'
                classNames='w-[calc(100%+2rem)] -left-4 -top-14'
              />
            )}
          </div>
          <CircleButton
            colorScheme={!controls.mic ? 'defaultActive' : 'default'}
            onClick={() => handleMicOrCameraClick('mic')}
            disabled={!!permissionsError}
          >
            <IconMicrophone
              fill={!controls.mic ? 'black' : 'white'}
              fontSize='1rem'
              width='100%'
              height='100%'
            />
          </CircleButton>
          <CircleButton
            colorScheme={!controls.camera ? 'defaultActive' : 'default'}
            onClick={() => handleMicOrCameraClick('camera')}
            disabled={!!permissionsError}
          >
            <IconCamera
              fill={!controls.camera ? 'black' : 'white'}
              fontSize='1rem'
              width='100%'
              height='100%'
            />
          </CircleButton>
          <CircleButton
            colorScheme={subtitlesShow ? 'defaultActive' : 'default'}
            onClick={() => setSubtitlesShow(!subtitlesShow)}
          >
            <IconClosedCaptioning
              fill={subtitlesShow ? 'black' : 'white'}
              fontSize='1rem'
              width='100%'
              height='100%'
            />
          </CircleButton>
          <CircleButton
            colorScheme={languagesShow ? 'defaultActive' : 'default'}
            onClick={() => setLanguagesShow(!languagesShow)}
          >
            <p
              className={`text-xs ${
                languagesShow ? 'text-black' : 'text-white'
              }`}
            >
              {lang.toUpperCase()}
            </p>
          </CircleButton>
          <CircleButton colorScheme='red' onClick={handleClose}>
            <IconXMark
              color='white'
              fontSize='1rem'
              width='100%'
              height='100%'
            />
          </CircleButton>
        </div>
        <Accordion show={subtitlesShow} setShow={setSubtitlesShow}>
          <div className='max-h-32 overflow-auto'>
            {map(steps, renderStep)}
            <p
              key={'speech'}
              className='text-gray-100 text-sm font-light italic'
            >
              {userName || 'Anonymous'}: {speech}
            </p>
            <div ref={messagesEndRef} />
          </div>
        </Accordion>
        <Accordion show={languagesShow} setShow={setLanguagesShow}>
          {map(availableLanguages, (l, i) => (
            <div
              className='flex items-center mb-4'
              key={l.id}
              onClick={() => onLangClick(l.id)}
            >
              <input
                id={`language_${l.id}`}
                // disabled={lang === l.id}
                // defaultChecked={i === 0}
                checked={lang === l.id}
                type='radio'
                value={lang}
                name={lang}
                className='w-4 h-4 text-teal-400 focus:ring-0 accent-[#38B2AC]'
              />
              <label
                htmlFor={`language_${i}`}
                className='ms-2 text-sm font-medium text-white'
              >
                {l.title}
              </label>
            </div>
          ))}
        </Accordion>
        <Accordion show={cameraSelectShow} setShow={setCameraSelectShow}>
          {map(devices, d => (
            <div
              className='flex items-center mb-4'
              key={d.deviceId}
              onClick={() => setSelectedDeviceId(d.deviceId)}
            >
              <input
                id={`device_${d.deviceId}`}
                type='radio'
                value={d.deviceId}
                name={d.label}
                className='w-4 h-4 text-teal-400 focus:ring-0 accent-[#38B2AC]'
                checked={selectedDeviceId === d.deviceId}
                onChange={() => setSelectedDeviceId(d.deviceId)}
              />
              <label
                htmlFor={`language_${d.deviceId}`}
                className='ms-2 text-sm font-medium text-white'
              >
                {d.label}
              </label>
            </div>
          ))}
        </Accordion>
        <div className='w-full mx-auto bg-blackAlpha-800 flex items-center justify-end p-1 pr-2'>
          <span className='pr-1 text-[6px] text-whiteAlpha-600'>
            Powered by
          </span>
          <FFLogo height={10} width={40} />
        </div>
      </div>
    </div>
  )
}

export default forwardRef(ControlPanel)
