import {
  useRef,
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState
} from 'react'

import * as sdk from '@d-id/client-sdk'

import RVideo from './recorder/Rvideo'
import get from 'lodash/get'
import has from 'lodash/has'
// import BackgroundRemover from './BackgroundRemover'
import jsLogger from 'js-logger'
import { IHeyGenConnect } from 'shared/components/HeyGenConnectSdk'

type Props = {
  onAvatarPlayingFinished: () => void
  onAvatarStartTalking: (latency: number) => void
  thereWasAnError?: string
  setThereWasAnError?: (v: string) => void
  onSessionStarted: () => void
  isRecording: boolean
  permissionsGranted: boolean
  handleChunk?: (videoBlob: Blob, mimeType: string, role: 'avatar') => void
  setDuration: (v: number) => void
  handleVideoClick: () => void
  avatarId?: string
  voiceId?: string
}

// const defaultAvatarId = 'a11567e7a1cf4be2bc7a92a0ce0355e2'

const DIDConnect: ForwardRefRenderFunction<IHeyGenConnect, Props> = (
  {
    onAvatarPlayingFinished,
    thereWasAnError,
    onAvatarStartTalking,
    setThereWasAnError,
    onSessionStarted,
    isRecording,
    permissionsGranted,
    handleChunk,
    setDuration,
    handleVideoClick,
    avatarId = 'default',
    voiceId
  },
  ref
) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoMutedRef = useRef<HTMLVideoElement>(null)
  const videoRecorderRef = useRef<RVideo>()
  const streamHasBeenSet = useRef<boolean>(false)
  const startTalkTimestamp = useRef<number>(0)
  const permissionsGrantedRef = useRef<boolean>(false)
  const canceledRef = useRef<boolean>(false)
  const sessionStartedRef = useRef<boolean>(false)

  const [stream, setStream] = useState<MediaStream>()

  const avatar = useRef<sdk.AgentManager | null>(null)
  const [showIdle, setShowIdle] = useState(true)
  const [idleVideoUrl, setIdleVideoUrl] = useState<string>()

  useImperativeHandle(ref, () => ({
    say: (t: string) => {
      jsLogger.log('say', t)
      startTalk(t)
    },
    cancel: cancelTalking,
    stop: stopSession,
    play
  }))

  const play = () => {
    if (!streamHasBeenSet.current) {
      playVideo()
    }
  }

  useEffect(() => {
    if (permissionsGranted && stream) {
      permissionsGrantedRef.current = true
      // setTimeout(playVideo, 500)
      playVideo()
    }
  }, [permissionsGranted, stream])

  const fireSessionStarted = () => {
    jsLogger.log('fireSessionStarted', {
      sessionStartedRef: sessionStartedRef.current
    })
    if (!sessionStartedRef.current) {
      sessionStartedRef.current = true
      jsLogger.log('CALL: onSessionStarted')
      onSessionStarted()
    }
  }

  useEffect(() => {
    const init = async () => {
      jsLogger.log('D-ID: Initializing')

      const agentId = 'agt_gK-7vGVa'
      const clientKey =
        'Z29vZ2xlLW9hdXRoMnwxMDc5MTk2MTY1NzkzNTY3OTQ0ODQ6VHc3bTgxbHBwN3pmVmtvRHUzZDM4'
      const auth: sdk.ClientKeyAuth = { type: 'key', clientKey }

      // const callbacks: sdk.ManagerCallbacks = {
      //   ,

      // }

      const options: sdk.AgentManagerOptions = {
        auth,
        debug: true,
        callbacks: {
          onSrcObjectReady: (value: MediaStream) => {
            jsLogger.log('DID: >>>>> Stream ready:', { streamId: value.id })
            setStream(value)
            setVideoElement(value)
          },
          onConnectionStateChange: (state: sdk.ConnectionState) => {
            jsLogger.log('DID: CONNECTION STATE', {
              state,
              permissionsGranted: permissionsGrantedRef.current
            })
            if (state === sdk.ConnectionState.Connected) {
              if (permissionsGrantedRef.current) {
                jsLogger.log('DID: onSessionStarted')
                fireSessionStarted()
              } else {
              }
            }
          },
          onVideoStateChange: (state: sdk.StreamingState) => {
            jsLogger.log('DID: onVideoStateChange(): ', {
              state,
              permissionsGranted: permissionsGrantedRef.current
            })
            if (
              state === sdk.StreamingState.Start &&
              permissionsGrantedRef.current
            ) {
              const latency = Date.now() - startTalkTimestamp.current
              onAvatarStartTalking(latency)
              setShowIdle(false)
            } else if (state === sdk.StreamingState.Stop) {
              const idleVideo = agentManager.agent.presenter.idle_video
              jsLogger.log('DID: idleVideo', { idleVideo })
              setShowIdle(true)
              onAvatarPlayingFinished()
              // videoElement.srcObject = null
              // const idleVideo = agentManager.agent.presenter.idle_video
              // if (idleVideo) {
              //   jsLogger.log('DID: set idle video', { idleVideo })
              //   videoElement.src = idleVideo
              // } else {
              //   jsLogger.log('DID: cannot set idle video')
              // }
            } else if (stream) {
              // videoElement.src = ''
              // videoElement.srcObject = stream
            }
            // } else {
            //   jsLogger.warn('DID: video element is not defined')
            // }
          },
          onError: (error, errorData) => {
            jsLogger.error('DID error:', { error, errorData })
          }
        },
        streamOptions: {
          streamWarmup: true
        }
      }

      const agentManager = await sdk.createAgentManager(agentId, options)
      jsLogger.log('DID agentManager', { agentManager })
      agentManager.connect()
      const idleVideo = agentManager.agent.presenter.idle_video
      if (idleVideo) {
        setIdleVideoUrl(idleVideo)
      }
      avatar.current = agentManager

      // streamingAvatar.on(
      //   StreamingEvents.AVATAR_STOP_TALKING,
      //   onAvatarPlayingFinished
      // )
    }
    init()

    return () => {
      endSession()
    }
  }, [])

  const endSession = async () => {
    jsLogger.log('DID: End session')
    const streamingAvatar: sdk.AgentManager | null = avatar.current
    if (!streamingAvatar) {
      jsLogger.error('endSession: Avatar API is not initialized')
      return
    }
    await streamingAvatar.disconnect()
  }

  const cancelTalking = async () => {
    jsLogger.log(
      'DID:  cancelTalking is already canceled:',
      canceledRef.current
    )
    const streamingAvatar: sdk.AgentManager | null = avatar.current
    if (!streamingAvatar) {
      jsLogger.error('DID: cancelTalking: Avatar API is not initialized')
      return
    }
    // if (!canceledRef.current) {
    jsLogger.log('DID: unterrupt is not supported')
    // try {
    //   canceledRef.current = true
    //   const interruptResponse = await streamingAvatar.interrupt()
    //   jsLogger.log('interruptResponse', interruptResponse)
    // } catch (e) {
    //   jsLogger.warn('cancelTalking error', e)
    // }
    // }
  }

  const startTalk = async (msg: string) => {
    const streamingAvatar: sdk.AgentManager | null = avatar.current
    if (!streamingAvatar) {
      jsLogger.error('DID: startTalk: Avatar API is not initialized')
      return
    }
    startTalkTimestamp.current = Date.now()
    try {
      const response = await streamingAvatar.speak(msg)
      jsLogger.log('DID: start talk response', response)
    } catch (error) {
      if (error instanceof Error) {
        jsLogger.error('DID: Error in startTalk:', error.message)
      } else {
        jsLogger.error('DID: Unknown error in startTalk')
      }
    }
  }

  const playVideo = () => {
    jsLogger.log(
      '%cplayVideo, permisssions granted',
      'color: gold;',
      permissionsGrantedRef.current
    )
    if (avatar.current && avatar.current.agent.status)
      if (!permissionsGrantedRef.current) {
        // const v = permissionsGranted ? videoRef.current : videoMutedRef.current
        jsLogger.log('playVideo: permissions are not granted, exit playVideo')
        return null
      }
    const v = videoRef.current
    // const v = videoRef.current
    if (stream && v) {
      if (!videoRecorderRef.current) {
        videoRecorderRef.current = new RVideo(
          stream,
          (v: number) => setDuration(v),
          onNewChunk
        )
      }
      jsLogger.log('pause current video')
      v.pause()

      v.srcObject = stream
      if (!permissionsGrantedRef.current) {
        v.autoplay = false
        v.loop = false
      }
      // v.muted = false
      streamHasBeenSet.current = true
      // v.muted = false
      const playPromise = v.play()
      if (v.paused && has(playPromise, 'next')) {
        v.play()
          .then(() => {
            jsLogger.log('--> Video Play success')
          })
          .catch(e => jsLogger.log('--> Video Play error', e.message))
      } else {
        jsLogger.log('Video is not paused')
      }
      if (permissionsGrantedRef.current) {
        fireSessionStarted()
      }
    } else {
      jsLogger.warn('playVideo stream or videoref is not ready')
    }
  }

  const setVideoElement = async (stream: MediaStream) => {
    jsLogger.log('setVideoElement', stream.id)
    jsLogger.log(
      'setVideoElement: permissions granted',
      permissionsGrantedRef.current
    )
    playVideo()
  }

  // const onEvent = (e: string) => {}

  // const startSession = async () => {
  //   // setIsLoadingSession(true);
  //   // await updateToken();
  //   const streamingAvatar: StreamingAvatar | null = avatar.current
  //   if (!streamingAvatar) {
  //     jsLogger.error('Avatar API is not initialized')
  //     return
  //   }

  //   const avatarConf: StartAvatarRequest = {
  //     quality: AvatarQuality.High,
  //     avatarName: avatarId
  //   }

  //   if (voiceId) {
  //     avatarConf.voice = {
  //       voiceId,
  //       emotion: VoiceEmotion.FRIENDLY
  //     }
  //   }
  //   try {
  //     const res = await streamingAvatar.createStartAvatar(avatarConf)
  //     jsLogger.log('Session data:', { sessionData: res })
  //     setData(res)
  //     setStream(avatar.current.mediaStream)
  //     setVideoElement(avatar.current.mediaStream)
  //   } catch (error) {
  //     console.error('Error starting avatar session:', error)
  //     jsLogger.error('There was an error starting the session.')
  //     throw error
  //   }
  //   // setIsLoadingSession(false)
  // }

  const stopSession = async () => {
    // const sessionId = sessionIdRef.current
    // if (videoRecorderRef.current) {
    //   videoRecorderRef.current.stop()
    // }
    // if (sessionId) {
    //   _stopSession(sessionId)
    // }
  }

  const onNewChunk = (blob: Blob) => {
    if (videoRecorderRef.current) {
      const mimeType = videoRecorderRef.current.mimeType
      if (mimeType) {
        handleChunk && handleChunk(blob, mimeType, 'avatar')
      }
    }
  }

  return (
    <div
      className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative'
      onClick={handleVideoClick}
    >
      {permissionsGranted && (
        <>
          <video
            key={'heygen_video'}
            id='heygen_video'
            ref={videoRef}
            className='w-full h-full object-cover absolute left-0 top-0 right-0 bottom-0 bg-blackAlpha-800'
            playsInline
            controls={false}
            autoPlay={false}
            muted={false}
            onAbort={() => jsLogger.log('Video: onAbort')}
            onError={e => jsLogger.log('Video: onError', get(e, 'message'))}
          />
          {/* <BackgroundRemover key={'heygenVideo'} videoRef={videoRef} /> */}
        </>
      )}
      {showIdle && (
        <video
          key={'muted_video'}
          id='muted_video'
          src={idleVideoUrl}
          // poster='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/avatar_initial_bg.png?alt=media&token=3653cda3-365d-4ddc-9e4b-2160bd949f7b'
          ref={videoMutedRef}
          className='w-full h-full object-cover absolute left-0 top-0 right-0 bottom-0'
          playsInline
          autoPlay
          loop
          muted
          onAbort={() => jsLogger.log('Muted Video: onAbort')}
          onError={e =>
            jsLogger.log('Muted Video: onError ' + get(e, 'message'))
          }
        />
      )}

      {/* {!permissionsGranted && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-gray-700 w-full h-full' />
      )} */}
      {isRecording && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-black/60 w-full h-full' />
      )}
      {thereWasAnError && (
        <div className='absolute bottom-0 left-0 bg-white'>
          <p className='text-red'>{thereWasAnError}</p>
        </div>
      )}
      {
        // voiceDetectorEnabled && (
        // <VoiceDetector
        //   streamRef={streamRef}
        //   onVoiceDetected={onVoiceDetected}
        // />
        // )
      }
    </div>
  )
}

export default forwardRef(DIDConnect)
